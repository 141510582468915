@tailwind base;
@tailwind components;
@tailwind utilities;

/* Webfont: PowerGrotesk-Bold */@font-face {
  font-family: 'PowerGrotesk';
  src: url('https://cosine.sh/fonts/PowerGrotesk-Bold.woff2') format('woff2'), /* Modern Browsers */
       url('https://cosine.sh/fonts/PowerGrotesk-Bold.otf') format('opentype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: PowerGrotesk-Regular */@font-face {
  font-family: 'PowerGrotesk';
  src: url('https://cosine.sh/fonts/PowerGrotesk-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('https://cosine.sh/fonts/PowerGrotesk-Regular.otf') format('opentype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: PowerGrotesk-UltraLight */@font-face {
  font-family: 'PowerGroteskUltLt';
  src: url('https://cosine.sh/fonts/PowerGrotesk-UltraLight.woff2') format('woff2'), /* Modern Browsers */
       url('https://cosine.sh/fonts/PowerGrotesk-UltraLight.otf') format('opentype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}


html {
  font-family: "PowerGrotesk", "-system-ui", sans-serif;
}
